import React from 'react'

import Icon, { IconProps } from './Icon'

const Element: React.FC<IconProps> = (props) => {
  return (
    <Icon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32.927"
        height="28.811"
        viewBox="0 0 32.927 28.811"
      >
        <path
          d="M30.869,10.482H2.058A2.058,2.058,0,0,1,0,8.424V4.308A2.058,2.058,0,0,1,2.058,2.25H30.869a2.058,2.058,0,0,1,2.058,2.058V8.424A2.058,2.058,0,0,1,30.869,10.482ZM27.782,4.822a1.543,1.543,0,1,0,1.543,1.543A1.543,1.543,0,0,0,27.782,4.822Zm-4.116,0A1.543,1.543,0,1,0,25.21,6.366,1.543,1.543,0,0,0,23.666,4.822Zm7.2,15.949H2.058A2.058,2.058,0,0,1,0,18.713V14.6A2.058,2.058,0,0,1,2.058,12.54H30.869A2.058,2.058,0,0,1,32.927,14.6v4.116A2.058,2.058,0,0,1,30.869,20.771Zm-3.087-5.659a1.543,1.543,0,1,0,1.543,1.543A1.543,1.543,0,0,0,27.782,15.112Zm-4.116,0a1.543,1.543,0,1,0,1.543,1.543A1.543,1.543,0,0,0,23.666,15.112Zm7.2,15.949H2.058A2.058,2.058,0,0,1,0,29V24.887a2.058,2.058,0,0,1,2.058-2.058H30.869a2.058,2.058,0,0,1,2.058,2.058V29A2.058,2.058,0,0,1,30.869,31.061ZM27.782,25.4a1.543,1.543,0,1,0,1.543,1.543A1.543,1.543,0,0,0,27.782,25.4Zm-4.116,0a1.543,1.543,0,1,0,1.543,1.543A1.543,1.543,0,0,0,23.666,25.4Z"
          transform="translate(0 -2.25)"
          fill="#341111"
        />
      </svg>
    </Icon>
  )
}

export default Element
